import { FullZustandState } from '../../../types';
import { updateState } from '../../models';

export const setMobileSelectedFilteredViewQuery = async (
  mobileSelectedFilteredViewQuery: string | null,
): Promise<void> => {
  await updateState(
    (state) => {
      state.mobileSelectedFilteredViewQuery = mobileSelectedFilteredViewQuery;
    },
    {
      eventName: 'mobile-selected-filtered-view-query-updated',
      userInteraction: 'unknown',
      isUndoable: false,
    },
  );
};

export const setFilterQueryToCreate = async (
  filterQueryToCreate: FullZustandState['filterQueryToCreate'],
): Promise<void> => {
  await updateState(
    (state) => {
      state.filterQueryToCreate = filterQueryToCreate;
    },
    { userInteraction: 'click', isUndoable: false, eventName: 'set-filter-query' },
  );
};

export const setAreViewsDocumentCountsIndexing = async (isIndexing: boolean): Promise<void> => {
  await updateState(
    (state) => {
      state.areViewsDocumentCountsIndexing = isIndexing;
    },
    { userInteraction: 'click', isUndoable: false, eventName: 'set-views-count-indexing' },
  );
};
